<template>
  <div>
    <KTPortlet title="Group Invoice Payment" v-if="canMultipay">
      <template slot="body">
        <div class="row">
          <div class="col-xl-6">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th sticky-column>Number</b-th>
                  <b-th>Amount</b-th>
                  <b-th>Due Date</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="item in multipayInvoices" v-bind:key="item.id">
                  <b-th sticky-column
                    >{{ item.proforma ? "PROF" : "INV" }}-{{
                      item.number
                    }}</b-th
                  >
                  <b-td>
                    {{
                      $tools.formatCurrency(
                        item.total - item.amountPaid,
                        2,
                        ".",
                        " ",
                        item.currencyCode
                      )
                    }}
                    <span v-if="item.amountPaid > 0"
                      >({{
                        $tools.formatCurrency(
                          item.total,
                          2,
                          ".",
                          " ",
                          item.currencyCode
                        )
                      }})</span
                    >
                  </b-td>
                  <b-td>
                    {{ $tools.formatDate(item.dueDate) }}
                    <v-chip
                      v-if="
                        new Date(item.dueDate).getTime() < new Date().getTime()
                      "
                      class="ma-2"
                      :style="
                        `background: var(--danger); color: #fff; margin-left: 10px;`
                      "
                      >OVERDUE</v-chip
                    >
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>TOTAL</b-th>
                  <b-th>{{
                    $tools.formatCurrency(
                      multipayTotal,
                      2,
                      ".",
                      " ",
                      multipayInvoices[0].currencyCode
                    )
                  }}</b-th>
                  <b-td />
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="col-xl-6">
            <InvoicePayments :invoiceIds="multipayInvoiceIds" />
          </div>
        </div>
      </template>
    </KTPortlet>
    <KTPortlet title="My Invoices" :fullContent="false">
      <template slot="body">
        <Loader
          v-if="invoices === null"
          :newStyle="2"
          :fields="[
            'Status',
            'Number',
            'Total',
            'Amount Due',
            'Created Date',
            'Due Date',
            ''
          ]"
        ></Loader>
        <div
          v-else-if="invoices.length === 0"
          style="width: 100%; text-align: center"
        >
          No invoices to display
        </div>
        <div v-else>
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Status</b-th>
                <b-th>Number</b-th>
                <b-th>Total</b-th>
                <b-th
                  v-if="
                    pagedItems.length > 0 &&
                      pagedItems[0].amountPaid !== undefined
                  "
                  >Amount Due</b-th
                >
                <b-th>Created Date</b-th>
                <b-th>Due Date</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in pagedItems" v-bind:key="item.id">
                <b-th>
                  <v-chip
                    v-if="
                      item.Status.state &&
                        item.amountPaid !== undefined &&
                        item.Total - item.amountPaid !== 0 &&
                        new Date(item.Due_Date).getTime() < new Date().getTime()
                    "
                    class="ma-2"
                    :style="
                      `background: var(--danger); color: #fff; margin-right: 10px;`
                    "
                    >OVERDUE</v-chip
                  >
                  <v-chip
                    class="ma-2"
                    :style="
                      `background: var(--${item.Status.class}); color: #fff;`
                    "
                  >
                    <v-icon left :class="item.Status.class" color="#fff">{{
                      item.Status.icon
                    }}</v-icon>
                    {{ item.Status.text }}
                  </v-chip>
                  <span
                    style="margin-left: 20px"
                    v-if="
                      item.proforma &&
                        item.Status.code == 5 &&
                        !$tools.isNullOrUndefined(item.generatedInvoiceId)
                    "
                    >INV-{{ findInvoice(item.generatedInvoiceId).number }}</span
                  >
                </b-th>
                <b-td
                  >{{ item.proforma ? "PROF" : "INV" }}-{{ item.Number }}</b-td
                >
                <b-td>
                  <span
                    :style="
                      `${
                        !item.Status.state
                          ? 'text-decoration: line-through;'
                          : ''
                      }`
                    "
                    >{{
                      $tools.formatCurrency(
                        item.Total,
                        2,
                        ".",
                        " ",
                        item.currencyCode
                      )
                    }}</span
                  >
                </b-td>
                <b-td v-if="item.amountPaid !== undefined">
                  <v-chip
                    v-if="
                      item.Status.state && item.Total - item.amountPaid !== 0
                    "
                    class="ma-2"
                    :style="`background: var(--warning); color: #fff;`"
                  >
                    <v-icon left class="warning" color="#fff">warning</v-icon>
                    {{
                      $tools.formatCurrency(
                        item.Total - item.amountPaid,
                        2,
                        ".",
                        " ",
                        item.currencyCode
                      )
                    }}
                  </v-chip>
                </b-td>
                <b-td>{{ $tools.formatDate(item.Created_Date) }}</b-td>
                <b-td>{{ $tools.formatDate(item.Due_Date) }}</b-td>
                <b-td>
                  <v-btn color="info" @click="showInvoice(item.id)"
                    >VIEW/PAY</v-btn
                  >
                  <!-- <v-btn
                    color="primary"
                    style="margin-left: 10px"
                    :disabled="busyDownloadingInvoice"
                    @click="triggerInvoicePDFDownload(item)"
                    >Download</v-btn
                  > -->
                  <!-- <v-btn class="mx-2" fab dark small @click="showInvoice(item.id)">
                  <v-icon dark>search</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  class="mx-2"
                  fab
                  dark
                  small
                  av-if="!((item.proforma && item.Status.code == 5) || item.Status.code === 4)"
                >
                  <v-icon dark>cloud_download</v-icon>
                  </v-btn>-->
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <b-modal
          hide-footer
          size="xl"
          ref="viewInvoiceModal"
          @hidden="hideViewModal()"
          :title="
            $tools.isNullOrUndefined(viewInvoice) && !viewInvoiceVisible
              ? 'Loading invoice'
              : `${viewInvoice.proforma ? 'PROF' : 'INV'}-${
                  viewInvoice.number
                }: ${$tools.getStatusInfoInvoice(viewInvoice.status).text}`
          "
        >
          <div
            v-if="
              viewInvoiceVisible &&
                !$tools.isNullOrUndefined(invoiceItems) &&
                !$tools.isNullOrUndefined(viewInvoice)
            "
          >
            <!-- <b-table
              :striped="true"
              :bordered="true"
              :borderless="true"
              :dark="false"
              :items="invoiceItems"
              :fields="invoiceTableFields"
              head-variant="dark"
            >
              <template
                v-slot:cell(Price)="row"
              >{{$tools.formatCurrency(row.item.Price, 2, '.', ' ', viewInvoice.currencyCode)}}</template>
              <template
                v-slot:cell(Total)="row"
              >{{$tools.formatCurrency(row.item.Total, 2, '.', ' ', viewInvoice.currencyCode)}}</template>
            </b-table> -->
            <table
              v-if="false"
              style="width: calc(100% - 40px); margin: 0 auto; font-size: 15px"
            >
              <tr>
                <td>
                  <div
                    v-if="
                      viewInvoice.status !== 4 &&
                        viewInvoice.total != viewInvoice.amountPaid
                    "
                  >
                    <h5>Payment Information</h5>
                    <div v-if="viewInvoice.amountPaid > 0">
                      Amount you have paid so far:
                      <b style="font-weight: 600; color: var(--success)">{{
                        $tools.formatCurrency(
                          viewInvoice.amountPaid,
                          2,
                          ".",
                          " ",
                          viewInvoice.currencyCode
                        )
                      }}</b>
                    </div>
                    <div v-if="viewInvoice.amountPaid > 0">
                      Amount still owed:
                      <b style="font-weight: 600; color: var(--warning)">{{
                        $tools.formatCurrency(
                          viewInvoice.total - viewInvoice.amountPaid,
                          2,
                          ".",
                          " ",
                          viewInvoice.currencyCode
                        )
                      }}</b>
                    </div>
                  </div>
                </td>
                <td style="text-align: right">
                  <div>
                    <div v-if="viewInvoice.total !== viewInvoice.subtotal">
                      Subtotal:
                      <b style="font-weight: 600">{{
                        $tools.formatCurrency(
                          viewInvoice.subtotal,
                          2,
                          ".",
                          " ",
                          viewInvoice.currencyCode
                        )
                      }}</b>
                    </div>
                    <div v-if="!$tools.isNullOrUndefined(viewInvoice.discount)">
                      Discount:
                      <b style="font-weight: 600">{{
                        $tools.formatCurrency(
                          viewInvoice.discount,
                          2,
                          ".",
                          " ",
                          viewInvoice.currencyCode
                        )
                      }}</b>
                    </div>
                    <div>
                      Total:
                      <b style="font-weight: 600">{{
                        $tools.formatCurrency(
                          viewInvoice.total,
                          2,
                          ".",
                          " ",
                          viewInvoice.currencyCode
                        )
                      }}</b>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- <tr v-if="viewInvoice.status !== 4 && viewInvoice.paymentCovers.length > 0">
              <td colspan="2">
                <div style="margin-top: 40px; width: 100%; text-align: center;">
                  <h5>Payment Logs</h5>
                  <div v-for="payment in viewInvoice.paymentCovers" v-bind:key="payment.id">
                    <div>
                      PAY-{{payment.paymentId}}:
                      <b
                        style="font-weight: 600;"
                      >{{$tools.formatCurrency(payment.amount, 2, '.', ' ', viewInvoice.currencyCode)}}</b>
                    </div>
                  </div>
                </div>
              </td>
              </tr>-->
            </table>

            <div
              v-if="
                ['unpaid', 'partial'].indexOf(viewInvoice.status) >= 0 &&
                  viewInvoice.total != viewInvoice.amountPaid
              "
              style="padding-top: 20px"
            >
              <h1 style="text-align: center; padding-bottom: 20px">
                Pay invoice
              </h1>
              <InvoicePayments
                :invoiceIds="[viewInvoice.id]"
                @hide="hideViewModal()"
              />
            </div>
            <!-- <v-btn
              color="primary"
              style="margin-bottom: 10px"
              :disabled="busyDownloadingInvoice"
              @click="triggerInvoicePDFDownload(viewInvoice)"
              >Download PDF</v-btn
            > -->
            <Loader
              v-if="!invoiceReady"
              :inContent="true"
              text="Rendering invoice"
            ></Loader>
            <iframe
              v-show="invoiceReady"
              :src="getInvoicePDFDownload(viewInvoice)"
              style="
                width: 100%;
                height: 100%;
                border: none;
                min-height: 1024px;
              "
            ></iframe>
          </div>
        </b-modal>
      </template>
      <template slot="foot">
        <div>
          <div v-if="invoices !== null">
            <v-pagination
              v-if="items.length > perPage"
              align="center"
              v-model="currentPage"
              :total-visible="7"
              circle
              :length="Math.ceil(items.length / perPage)"
              :per-page="perPage"
            ></v-pagination>
          </div>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import MOMENT from "moment";
import { mapGetters } from "vuex";
import InvoicePayments from "./invoices.payment";
//import CONFIG from "@/common/config";

export default {
  components: {
    KTPortlet,
    Loader,
    InvoicePayments
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      invoices: null,
      tableFields: [
        "Status",
        "Number",
        "Total",
        "Amount_Due",
        "Created_Date",
        "Due_Date",
        "Actions"
      ],
      invoiceReady: false,
      canMultipay: false,
      multipayTotal: 0,
      multipayInvoices: [],
      multipayInvoiceIds: [],
      viewInvoiceVisible: false,
      viewInvoice: null,
      viewInvoicePayfastPayment: null,
      invoiceItems: null,
      viewInvoiceKey: "",
      invoiceTableFields: ["Item", "Price", "QTY", "Total"],
      payfastPaymentSignature: null,
      busyDownloadingInvoice: false
    };
  },
  methods: {
    getInvoicePDFDownload(invoice) {
      return (
        this.$api.getBaseURL("billing") +
        `/${this.$store.getters.clientId}/invoices/${invoice.id}/1?passtk=${
          this.$store.getters.token
        }`
      );
    },
    triggerInvoicePDFDownload(invoice) {
      const self = this;
      self.$data.busyDownloadingInvoice = true;
      this.$api
        .triggerDownloadFile(
          `/${this.$store.getters.clientId}/invoices/${invoice.id}/pdf`,
          `${invoice.number}.pdf`,
          "billing"
        )
        .then(() => {
          setTimeout(() => {
            self.$data.busyDownloadingInvoice = false;
          }, 3000);
        })
        .catch(() => {
          self.$data.busyDownloadingInvoice = false;
        });
    },
    hideViewModal() {
      this.$data.viewInvoiceVisible = false;
      this.$router.push("/MyAccount/Invoices");
      this.$refs.viewInvoiceModal.hide();
    },
    _showInvoice(obj) {
      this.$router.push(`/MyAccount/Invoices/${obj.id}`);
      this.$data.viewInvoice = obj;
      this.$data.viewInvoicePayfastPayment = null;
      const thisKey = `${new Date().getTime()}`;
      this.$data.viewInvoiceKey = `${thisKey}`;
      //let self = this;
      /*if (obj.status !== 3) {
        self.$nextTick(() => {
          ApiService.get(
            `billing/invoice/${obj.id}/payfast?cancel_url=${encodeURIComponent(
              self.winLocation
            )}&return_url=${encodeURIComponent(self.winLocation)}`
          )
            .then(({ data }) => {
              if (thisKey !== self.viewInvoiceKey) return;

              self.$data.viewInvoicePayfastPayment = data;
            })
            .catch(({ response }) => {
              self.$log.error(response);
            });
        });
      }*/
      let itemData = [];
      for (let item of obj.items)
        itemData.push({
          id: item.id,
          number: item.number,
          Item: item.label,
          Price: item.price,
          QTY: item.quantity,
          Total: item.total,
          Type: item.type,
          ServiceId: item.serviceId
        });
      this.$data.invoiceItems = itemData;
      this.$data.viewInvoiceVisible = true;
      this.$refs.viewInvoiceModal.show();
    },
    showInvoice(id) {
      this.invoiceReady = false;
      for (let inv of this.invoices)
        if (inv.id == id || inv.number === id) return this._showInvoice(inv);
      return this.hideViewModal();
    },
    findInvoice(id) {
      for (let inv of this.invoices) if (inv.id == id) return inv;
      return null;
    },
    UpdateInvoiceData(bustacache = false) {
      const self = this;
      this.$api
        .getWithCache(
          self,
          "billing",
          `${this.$store.getters.clientId}/invoices`,
          "my-account:billing-invoices",
          bustacache
        )
        .then(data => {
          self.$data.currentPage = 1;
          self.$data.invoices = data;

          if (!self.$tools.isNullOrUndefined(self.$route.params.invoiceId)) {
            if (self.viewInvoiceVisible) {
              self.$data.viewInvoiceVisible = false;
            }
            self.$nextTick(() => {
              self.showInvoice(self.$route.params.invoiceId);
            });
          }

          /*let invoiceDueCount = 0;
          self.$data.multipayInvoices = [];
          self.$data.multipayInvoiceIds = [];
          self.$data.multipayTotal = 0;
          for (let row of data) {
            if ([1, 2].indexOf(row.status) >= 0) {
              if (row.total - row.amountPaid > 0) {
                self.$data.multipayTotal += row.total - row.amountPaid;
                invoiceDueCount++;
                self.$data.multipayInvoices.push(row);
                self.$data.multipayInvoiceIds.push(row.id);
              }
            }
          }

          self.$data.canMultipay = invoiceDueCount > 1;*/
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    invoiceIframeNotif(event) {
      const self = this;
      if (typeof event.data === "object") {
        if (event.data.action === "INVOICE-IFRAME") {
          //console.log("SYS Event");
          //console.log(event);
          if (event.data.message === "LOADED") {
            self.invoiceReady = true;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    winLocation() {
      return window.location.href;
    },
    clientId() {
      let clientInfo = JSON.parse(window.sessionStorage.getItem("client"));
      return clientInfo.id;
    },
    userCellNumber() {
      let clientInfo = JSON.parse(window.sessionStorage.getItem("client"));
      for (let contact in clientInfo.contacts) {
        if (contact.email === this.currentUser.email) {
          return contact.phone;
        }
      }
      return null;
    },
    items() {
      if (this.invoices === null) return [];
      let dataToReturn = [];
      let sortedItems = this.invoices;
      for (let ia = 0; ia < sortedItems.length; ia++) {
        for (let ib = 0; ib < sortedItems.length; ib++) {
          if (
            MOMENT(sortedItems[ia].createdDate).diff(
              MOMENT(sortedItems[ib].createdDate)
            ) > 0
          ) {
            let temp = sortedItems[ia];
            sortedItems[ia] = sortedItems[ib];
            sortedItems[ib] = temp;
          }
        }
      }

      for (let row of sortedItems) {
        dataToReturn.push({
          Status: this.$tools.getStatusInfoInvoice(row.status),
          Number: row.number,
          number: row.number,
          Total: row.total,
          amountPaid: row.amountPaid,
          Created_Date: row.createdDate,
          Due_Date: row.dueDate,
          generatedInvoiceId: row.generatedInvoiceId,
          proformaInvoiceId: row.proformaInvoiceId,
          proforma: row.proforma,
          id: row.id,
          currencyCode: row.currencyCode,
          currencySym: row.currencySym
        });
      }
      return dataToReturn;
    },
    pagedItems() {
      let listToReturn = [];

      let maxPage = this.perPage * this.currentPage;
      if (maxPage > this.items.length) maxPage = this.items.length;
      for (let i = this.perPage * (this.currentPage - 1); i < maxPage; i++) {
        listToReturn.push(this.items[i]);
      }

      return listToReturn;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`evu-invoice`);
    this.$eventBus.off(`evu-payment`);
    this.$eventBus.off(`cache-my-account:billing-invoices`);
    if (window.addEventListener) {
      window.removeEventListener("message", this.invoiceIframeNotif);
    } else {
      window.detachEvent("onmessage", this.invoiceIframeNotif);
    }
  },
  mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.invoiceIframeNotif);
    } else {
      window.attachEvent("onmessage", this.invoiceIframeNotif);
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "My Account" },
      { title: "Billing" },
      { title: "Invoices" }
    ]);
    /*let cacheOfServices = this.$cache.get("my-account:invoices");
    if (cacheOfServices) {
      return (this.$data.invoices = cacheOfServices);
    }*/
    let self = this;
    self.$eventBus.on(`evu-invoice`, () => self.UpdateInvoiceData(true));
    self.$eventBus.on(`evu-payment`, () => self.UpdateInvoiceData(true));
    self.$eventBus.on(`cache-my-account:billing-invoices`, () =>
      self.UpdateInvoiceData(false)
    );
    self.UpdateInvoiceData();
  }
};
</script>
