var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.canMultipay)?_c('KTPortlet',{attrs:{"title":"Group Invoice Payment"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v("Number")]),_c('b-th',[_vm._v("Amount")]),_c('b-th',[_vm._v("Due Date")])],1)],1),_c('b-tbody',[_vm._l((_vm.multipayInvoices),function(item){return _c('b-tr',{key:item.id},[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v(_vm._s(item.proforma ? "PROF" : "INV")+"-"+_vm._s(item.number))]),_c('b-td',[_vm._v(" "+_vm._s(_vm.$tools.formatCurrency( item.total - item.amountPaid, 2, ".", " ", item.currencyCode ))+" "),(item.amountPaid > 0)?_c('span',[_vm._v("("+_vm._s(_vm.$tools.formatCurrency( item.total, 2, ".", " ", item.currencyCode ))+")")]):_vm._e()]),_c('b-td',[_vm._v(" "+_vm._s(_vm.$tools.formatDate(item.dueDate))+" "),(
                      new Date(item.dueDate).getTime() < new Date().getTime()
                    )?_c('v-chip',{staticClass:"ma-2",style:(`background: var(--danger); color: #fff; margin-left: 10px;`)},[_vm._v("OVERDUE")]):_vm._e()],1)],1)}),_c('b-tr',[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v("TOTAL")]),_c('b-th',[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.multipayTotal, 2, ".", " ", _vm.multipayInvoices[0].currencyCode )))]),_c('b-td')],1)],2)],1)],1),_c('div',{staticClass:"col-xl-6"},[_c('InvoicePayments',{attrs:{"invoiceIds":_vm.multipayInvoiceIds}})],1)])])],2):_vm._e(),_c('KTPortlet',{attrs:{"title":"My Invoices","fullContent":false}},[_c('template',{slot:"body"},[(_vm.invoices === null)?_c('Loader',{attrs:{"newStyle":2,"fields":[
          'Status',
          'Number',
          'Total',
          'Amount Due',
          'Created Date',
          'Due Date',
          ''
        ]}}):(_vm.invoices.length === 0)?_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" No invoices to display ")]):_c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Status")]),_c('b-th',[_vm._v("Number")]),_c('b-th',[_vm._v("Total")]),(
                  _vm.pagedItems.length > 0 &&
                    _vm.pagedItems[0].amountPaid !== undefined
                )?_c('b-th',[_vm._v("Amount Due")]):_vm._e(),_c('b-th',[_vm._v("Created Date")]),_c('b-th',[_vm._v("Due Date")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((_vm.pagedItems),function(item){return _c('b-tr',{key:item.id},[_c('b-th',[(
                    item.Status.state &&
                      item.amountPaid !== undefined &&
                      item.Total - item.amountPaid !== 0 &&
                      new Date(item.Due_Date).getTime() < new Date().getTime()
                  )?_c('v-chip',{staticClass:"ma-2",style:(`background: var(--danger); color: #fff; margin-right: 10px;`)},[_vm._v("OVERDUE")]):_vm._e(),_c('v-chip',{staticClass:"ma-2",style:(`background: var(--${item.Status.class}); color: #fff;`)},[_c('v-icon',{class:item.Status.class,attrs:{"left":"","color":"#fff"}},[_vm._v(_vm._s(item.Status.icon))]),_vm._v(" "+_vm._s(item.Status.text)+" ")],1),(
                    item.proforma &&
                      item.Status.code == 5 &&
                      !_vm.$tools.isNullOrUndefined(item.generatedInvoiceId)
                  )?_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("INV-"+_vm._s(_vm.findInvoice(item.generatedInvoiceId).number))]):_vm._e()],1),_c('b-td',[_vm._v(_vm._s(item.proforma ? "PROF" : "INV")+"-"+_vm._s(item.Number))]),_c('b-td',[_c('span',{style:(`${
                      !item.Status.state
                        ? 'text-decoration: line-through;'
                        : ''
                    }`)},[_vm._v(_vm._s(_vm.$tools.formatCurrency( item.Total, 2, ".", " ", item.currencyCode )))])]),(item.amountPaid !== undefined)?_c('b-td',[(
                    item.Status.state && item.Total - item.amountPaid !== 0
                  )?_c('v-chip',{staticClass:"ma-2",style:(`background: var(--warning); color: #fff;`)},[_c('v-icon',{staticClass:"warning",attrs:{"left":"","color":"#fff"}},[_vm._v("warning")]),_vm._v(" "+_vm._s(_vm.$tools.formatCurrency( item.Total - item.amountPaid, 2, ".", " ", item.currencyCode ))+" ")],1):_vm._e()],1):_vm._e(),_c('b-td',[_vm._v(_vm._s(_vm.$tools.formatDate(item.Created_Date)))]),_c('b-td',[_vm._v(_vm._s(_vm.$tools.formatDate(item.Due_Date)))]),_c('b-td',[_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.showInvoice(item.id)}}},[_vm._v("VIEW/PAY")])],1)],1)}),1)],1)],1),_c('b-modal',{ref:"viewInvoiceModal",attrs:{"hide-footer":"","size":"xl","title":_vm.$tools.isNullOrUndefined(_vm.viewInvoice) && !_vm.viewInvoiceVisible
            ? 'Loading invoice'
            : `${_vm.viewInvoice.proforma ? 'PROF' : 'INV'}-${
                _vm.viewInvoice.number
              }: ${_vm.$tools.getStatusInfoInvoice(_vm.viewInvoice.status).text}`},on:{"hidden":function($event){return _vm.hideViewModal()}}},[(
            _vm.viewInvoiceVisible &&
              !_vm.$tools.isNullOrUndefined(_vm.invoiceItems) &&
              !_vm.$tools.isNullOrUndefined(_vm.viewInvoice)
          )?_c('div',[(false)?_c('table',{staticStyle:{"width":"calc(100% - 40px)","margin":"0 auto","font-size":"15px"}},[_c('tr',[_c('td',[(
                    _vm.viewInvoice.status !== 4 &&
                      _vm.viewInvoice.total != _vm.viewInvoice.amountPaid
                  )?_c('div',[_c('h5',[_vm._v("Payment Information")]),(_vm.viewInvoice.amountPaid > 0)?_c('div',[_vm._v(" Amount you have paid so far: "),_c('b',{staticStyle:{"font-weight":"600","color":"var(--success)"}},[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.viewInvoice.amountPaid, 2, ".", " ", _vm.viewInvoice.currencyCode )))])]):_vm._e(),(_vm.viewInvoice.amountPaid > 0)?_c('div',[_vm._v(" Amount still owed: "),_c('b',{staticStyle:{"font-weight":"600","color":"var(--warning)"}},[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.viewInvoice.total - _vm.viewInvoice.amountPaid, 2, ".", " ", _vm.viewInvoice.currencyCode )))])]):_vm._e()]):_vm._e()]),_c('td',{staticStyle:{"text-align":"right"}},[_c('div',[(_vm.viewInvoice.total !== _vm.viewInvoice.subtotal)?_c('div',[_vm._v(" Subtotal: "),_c('b',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.viewInvoice.subtotal, 2, ".", " ", _vm.viewInvoice.currencyCode )))])]):_vm._e(),(!_vm.$tools.isNullOrUndefined(_vm.viewInvoice.discount))?_c('div',[_vm._v(" Discount: "),_c('b',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.viewInvoice.discount, 2, ".", " ", _vm.viewInvoice.currencyCode )))])]):_vm._e(),_c('div',[_vm._v(" Total: "),_c('b',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.$tools.formatCurrency( _vm.viewInvoice.total, 2, ".", " ", _vm.viewInvoice.currencyCode )))])])])])])]):_vm._e(),(
              ['unpaid', 'partial'].indexOf(_vm.viewInvoice.status) >= 0 &&
                _vm.viewInvoice.total != _vm.viewInvoice.amountPaid
            )?_c('div',{staticStyle:{"padding-top":"20px"}},[_c('h1',{staticStyle:{"text-align":"center","padding-bottom":"20px"}},[_vm._v(" Pay invoice ")]),_c('InvoicePayments',{attrs:{"invoiceIds":[_vm.viewInvoice.id]},on:{"hide":function($event){return _vm.hideViewModal()}}})],1):_vm._e(),(!_vm.invoiceReady)?_c('Loader',{attrs:{"inContent":true,"text":"Rendering invoice"}}):_vm._e(),_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(_vm.invoiceReady),expression:"invoiceReady"}],staticStyle:{"width":"100%","height":"100%","border":"none","min-height":"1024px"},attrs:{"src":_vm.getInvoicePDFDownload(_vm.viewInvoice)}})],1):_vm._e()])],1),_c('template',{slot:"foot"},[_c('div',[(_vm.invoices !== null)?_c('div',[(_vm.items.length > _vm.perPage)?_c('v-pagination',{attrs:{"align":"center","total-visible":7,"circle":"","length":Math.ceil(_vm.items.length / _vm.perPage),"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }