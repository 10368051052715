<template>
  <v-container fluid>
    <template
      v-if="
        selectedPaymentMethod !== null &&
          selectedPaymentMethod.type === 'iframe'
      "
    >
      <Loader v-if="!selectedPaymentMethodReady" :inContent="true"
        >Loading...</Loader
      >
      <iframe
        v-show="selectedPaymentMethodReady"
        :src="paymentRequests[selectedPaymentMethod.key].url"
        style="
          border: none;
          width: 350px;
          height: 250px;
          overflow: hidden;
          margin: 0 auto;
          display: block;
        "
      ></iframe>

      <b-button variant="danger" @click="cancelPayment()" style="margin: 0 auto"
        >Cancel/Back</b-button
      >
    </template>
    <v-row dense v-else>
      <v-col style="display: flex; flex-wrap: wrap">
        <template
          v-if="availPaymentMethods !== null && availPaymentMethods.length > 0"
        >
          <v-card class="mx-auto" width="300" v-if="false">
            <div style="padding: 10px; height: 200px">
              <v-img
                class="text-success align-end"
                style="opacity: 0.3"
                height="200px"
                src="/assets/media/icons/svg/bank.svg"
              ></v-img>
              <!-- <v-img
                        style="width: 189.867px; height: 50px; position: relative; margin-top: -60px; margin-right: 10px; float: right;"
                        src="/assets/media/svgs/PayFast-Actual.svg"
            ></v-img>-->
            </div>

            <v-card-title style="text-align: center; display: block"
              >Linked card</v-card-title
            >
            <v-card-actions>
              <b-button variant="success" style="margin: 0 auto"
                >Pay
                {{
                  $tools.formatCurrency(amountToPay, 2, ".", " ", currencyCode)
                }}
                with linked card</b-button
              >
            </v-card-actions>
          </v-card>
          <v-card
            class="mx-auto"
            width="300"
            style="margin-bottom: 30px"
            v-for="method in availPaymentMethods"
            v-bind:key="method.key"
          >
            <div style="padding: 10px; height: 200px">
              <v-img
                style="
                  width: 265.8px;
                  max-height: 140px;
                  line-height: 200px;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
                "
                :src="`/assets/media/svgs/${method.key}.svg`"
              ></v-img>
            </div>

            <v-card-title style="text-align: center; display: block">{{
              method.name
            }}</v-card-title>
            <v-card-actions>
              <v-btn
                color="success"
                style="margin: 0 auto"
                @click="startPayment(method.key)"
                :disabled="paymentRequests[method.key] == null"
                :loading="paymentRequests[method.key] == null"
                >Pay
                {{
                  !$tools.isNullOrUndefined(paymentRequests[method.key])
                    ? $tools.formatCurrency(
                        paymentRequests[method.key].amount,
                        2,
                        ".",
                        " ",
                        method.currencySym
                      )
                    : ""
                }}
                with {{ method.name }}</v-btn
              >
              <!-- <form
              v-if="!$tools.isNullOrUndefined(payfastPayment1)"
              :action="payfastPayment1.url"
              method="POST"
              style="display: hidden"
              ref="payWithPayfast1"
            >
              <input
                v-for="field in Object.keys(payfastPayment1.data)"
                v-bind:key="field"
                type="hidden"
                :name="field"
                :value="payfastPayment1.data[field]"
              />
            </form> -->
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import { mapGetters } from "vuex";
import Loader from "@/views/partials/content/Loader.vue";

export default {
  components: {
    //KTPortlet,
    Loader
  },
  props: ["invoiceIds"],
  data() {
    return {
      paymentProviders: null,
      selectedPaymentMethod: null,
      selectedPaymentMethodReady: false,
      paymentRequests: {
        yoco: null,
        payfast: null
      },
      paymentTypes: {
        yoco: ["card"],
        payfast: ["card", "eft"]
      },
      payfastPayment1: null,
      availPaymentMethods: null,
      paymentMethods: [
        {
          name: "Yoco",
          key: "yoco",
          type: "iframe",
          currencyCode: "ZAR",
          currencySymc: "R"
        },
        {
          name: "PayFast",
          key: "payfast",
          type: "iframe",
          currencyCode: "ZAR",
          currencySymc: "R"
        }
      ]
    };
  },
  methods: {
    cancelPayment() {
      this.$data.selectedPaymentMethod = null;
      this.$data.selectedPaymentMethodReady = false;
    },
    startPayment(method) {
      for (let pm of this.paymentMethods)
        if (pm.key === method) return (this.$data.selectedPaymentMethod = pm);
    },
    startPaymentReq(method) {
      let self = this;
      self.$data.selectedPaymentMethodReady = false;
      self.$nextTick(() => {
        self.$api
          .get(
            "billing",
            `${this.$store.getters.clientId}/invoice/${self.invoiceIds.join(
              ","
            )}/pay?provider=${method}&cancelUrl=${encodeURIComponent(
              self.winLocation
            )}&returnUrl=${encodeURIComponent(self.winLocation)}`
          )
          .then(({ data }) => {
            self.$data.paymentRequests[method] = {
              ...data,
              amount: Number.parseFloat((data.data || data.request).amount)
            };
          })
          .catch(({ response }) => {
            self.$log.error(response);
            for (let i = 0; i < self.availPaymentMethods.length; i++) {
              if (self.availPaymentMethods[i].key === method) {
                self.$data.availPaymentMethods.splice(i, 1);
                return;
              }
            }
          });
      });
    },
    paymentIframeNotif(event) {
      const self = this;
      if (typeof event.data === "object") {
        if (event.data.action === "PAYMENT-IFRAME") {
          //console.log("SYS Event");
          //console.log(event);
          if (event.data.message === "PAYMENT-REDIRECT") {
            window.location.href = event.data.meta.url;
            return;
          }
          if (event.data.message === "PAYMENT-READY") {
            self.$data.selectedPaymentMethodReady = true;
            return;
          }
          if (event.data.message === "PAYMENT-SUCCESS") {
            self.$root.$bvToast.toast(
              "Payment completed. Please give the invoice list below a bit of time to reflect with your payment.",
              {
                title: `Payment success`,
                variant: "success",
                solid: true
              }
            );
            return setTimeout(() => self.$emit("hide"), 100);
          }
          if (event.data.message === "PAYMENT-ERROR") {
            return self.$bvToast.toast(event.data.errorMessage, {
              title: `Payment error`,
              variant: "danger",
              solid: true
            });
          }
        }
      }
    },
    updatePaymentServices(/*bumpacache = false*/) {
      let self = this;
      this.$api
        .getWithCache(
          self,
          "billing",
          `${this.$store.getters.clientId}/payments/methods-available`,
          "billing:payments-avail"
          //bumpacache
        )
        .then(data => {
          self.$data.paymentProviders = data;
          self.$data.availPaymentMethods = [];
          for (let pm of self.paymentProviders) {
            let ok = false;
            for (let pprov of self.paymentMethods) {
              if (pprov.key === pm) {
                ok = pprov;
                break;
              }
            }
            if (ok === false) continue;
            self.$data.availPaymentMethods.push(ok);
            self.startPaymentReq(pm);
          }
        })
        .catch(response => {
          self.$log.error(response);
        });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),

    winLocation() {
      //if (window.location.href.indexOf('http://localhost') === 0)
      //return `https://ninja.bettercorp.co.za/MyAccount/Invoices`;
      return window.location.href;
    }
    /*currencyCode() {
      let clientInfo = JSON.parse(window.sessionStorage.getItem("client"));
      return clientInfo.currencyCode;
    },*/
    /*clientId() {
      let clientInfo = JSON.parse(window.sessionStorage.getItem("client"));
      return clientInfo.id;
    },
    userCellNumber() {
      let clientInfo = JSON.parse(window.sessionStorage.getItem("client"));
      for (let contact in clientInfo.contacts) {
        if (contact.email === this.currentUser.email) {
          return contact.phone;
        }
      }
      return null;
    },*/
  },
  beforeDestroy() {
    if (window.addEventListener) {
      window.removeEventListener("message", this.paymentIframeNotif);
    } else {
      window.detachEvent("onmessage", this.paymentIframeNotif);
    }
  },
  mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.paymentIframeNotif);
    } else {
      window.attachEvent("onmessage", this.paymentIframeNotif);
    }
    this.updatePaymentServices();
  }
};
</script>
